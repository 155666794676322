//================================================================
//  Component: Tooltip
//================================================================

//  Purpose: This is the standard tooltip

//  Properties:
//    - message = {A string, this is the tooltip text}
//    - children = {HTML, this is the content for the tooltip}

//  Example:
//    <Tooltip
//      message={'Coming Soon!'}
//      children={<button>Subscribe</button>}
//    ></Tooltip>    

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

export default function Tooltip({
    message,
    children
}) {
    return (
        <div className='group relative flex'>
            {children}
            <span className='whitespace-nowrap font-medium absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100' hidden={!message}>{message}</span>
        </div>
    )
}