//================================================================
//  Component: PO Tab
//================================================================

//  Purpose: This contains the details of the procurement task

//  Properties:
//    - task = {An object, contains the procurement task details}
//    - setPageStatus = {A useState, used to determine the status of the page}

//  Example:
//    <POTab
//      task={task}
//      setPageStatus={setPageStatus}
//    ></POTab>    

// ==============================================================

// Libraries
import React, { useState, useReducer, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// Contexts
import { SetToast } from '../../../../Library/GlobalContexts';
import { GetUser } from '../../../../Library/GlobalContexts';

// Components
import CommentsThread from './CommentsThread';
import KebabDropdown from '../../../../Components/KebabDropdown/KebabDropdown';
import Tooltip from '../../../../Components/Tooltip/Tooltip';
import EditPOLineItemPane from './EditPOLineItemPane';
import AddPOLineItemPane from './AddPOLineItemPane';
import CompleteRequestModal from './Modals/CompleteRequestModal';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import QueryListener from '../../../../Library/QueryListener';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';
import Add from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddDisabled from '../../../../Components/Images/Icon_Add_Grey.svg';

// CSS
import '../../Task.css'

export default function POTab({
    task,
    setPageStatus
}) {

    //------------------------------------------------------
    //  useContexts and React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);
    const navigate = useNavigate();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to toggle the visibility of the add approver pane
    const [addPOLineItemPaneOpen, setAddPOLineItemPaneOpen] = useState(false);

    // Used to toggle the visibility of the add approver pane
    const [editPOLineItemPaneOpen, setEditPOLineItemPaneOpen] = useState(false);

    // Used to store the selected PO Line Item
    const [selectedPOLineItemIndex, setSelectedPOLineItemIndex] = useState(null);

    // Used to toggle the visibility of the complete request modal
    const [completeRequestModal, setCompleteRequestModal] = useState(false);

    // Used to store the checkbox state - whether the user chooses to close the ticket despite there being pending tasks
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    // Used to store all pending 'CSM' tasks
    const [pendingTasks, setPendingTasks] = useState([]);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'requestInformationCollapsed': true,
            'poRequestInformationCollapsed': true,
            'contractInformationCollapsed': true,
            'financialInformationCollapsed': true,
            'commentsCollapsed': true
        }
    );

    // Used to store the form inputs
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'currency': task?.currency,
            'estannualcontractvalue': task?.estannualcontractvalue,
            'esttotalcontractvalue': task?.esttotalcontractvalue,
            'ponumber': task?.ponumber,
        }
    );


    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handle edit button click and set the selected PO line item
    const handlePOItemEditClick = (polineitemIndex) => {

        // Set the selected PO Line Item Index
        setSelectedPOLineItemIndex(polineitemIndex);

        // Open the Edit PO Line Item Pane
        setEditPOLineItemPaneOpen(true);

    };

    // Handle delete button click and set the selected PO line item
    const handlePOItemDeleteClick = (polineitemIndex) => {

        const updatedPOLineItems = task?.polineitems.filter((item, index) => index !== polineitemIndex);

        // Update the document
        const updatedDoc = {
            'polineitems': updatedPOLineItems
        };

        WriteDocument('tasks', task?.taskid, updatedDoc, true)
        .catch((error) => {

            console.log('error', error);

            setToast({
                'type': 'error',
                'message': 'Failed to delete PO Line Item',
            });


        })
    };

    // Function used handle the 'Complete' button click
    function handleCompleteClick() {

        // Check if there are any pending tasks
        if (pendingTasks.length > 0) {

            // Set the complete request modal to true
            setCompleteRequestModal(true);

        } else {

            // Proceed to 'Complete' stage
            closeTicket();

        }

    }

    // Function used to proceed to 'Complete' stage
    function closeTicket() {

        // Set task status to 'Completed'
        const updatedDoc = {
            'status': 'Completed',
            'currency': formData?.currency,
            'estannualcontractvalue': formData?.estannualcontractvalue,
            'esttotalcontractvalue': formData?.esttotalcontractvalue,
            'ponumber': formData?.ponumber,
            'lastmodifiedby': {
                'email': getUser.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(),
            'activityfeed': [
                ...task?.activityfeed,
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': getUser?.emailaddress,
                        'givenname': getUser?.givenname,
                        'surname': getUser?.surname
                    },
                    'action': 'completed',
                    'comments': ''
                }
            ]
        };

        WriteDocument('tasks', task?.taskid, updatedDoc, true)
        .then(() => {

            setPageStatus('Completed');

            // Go to the Triage Tab
            navigate(`/${window.location.pathname.split('/')[1]}/${task?.taskid}?view=triage`);

        })
        .catch((error) => {

            setToast({
                'type': 'error',
                'message': 'Failed to close the ticket',
            });

            console.log('Error', error)

        })

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Query Listener
    // - Get all pending tasks
    useEffect(() => {

        if (task?.requestid === undefined) return;

        function onLoadChange(document) {

            // Filter by status
            if (document.length > 0) {
                document = document.filter((task) => task.status === 'Not Started' || task.status === 'In Progress');
            }

            setPendingTasks(document);
        }

        function onError(error) {

            console.log(error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get pending tasks related to this request.',
            });
        }

        const unsubscribe = QueryListener('tasks', [
            ['requestid', '==', task?.requestid],
            ['tasktype', 'not-in', ['Business Request', 'Procurement']],
        ], onLoadChange, onLoadChange, onError);


        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [task])



    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*  Request Information                                  */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'requestInformationCollapsed': !taskSection.requestInformationCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.requestInformationCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Request Information</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.requestInformationCollapsed}></hr>

                {/* ------------- Request Information  ------------- */}

                <div className='p-[3%] pt-3' hidden={taskSection?.requestInformationCollapsed}>

                    {/* =========================================================== */}
                    {/*  1. Request Type                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Request Type </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.requesttype}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  2. Name of Requester                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Name of Requester </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.requestername}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  3. Short Description of Request                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Short Description of Request </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '100%' }}
                            type='text'
                            value={task?.shortdescription}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  4. Detailed Request / Scope Description                    */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Detailed Request / Scope Description </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            defaultValue={task?.commercialscope}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  5.  Name of Sponsor                                        */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Name of Sponsor </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '100%' }}
                            type='text'
                            value={task?.sponsorname.email}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>


            {/* ===================================================== */}
            {/*  Contract Information                                 */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'contractInformationCollapsed': !taskSection.contractInformationCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.contractInformationCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Contract Information</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.contractInformationCollapsed}></hr>

                {/* ------------- Contract Information ------------- */}

                <div className='p-[3%] pt-3' hidden={taskSection?.contractInformationCollapsed}>

                    {/* =========================================================== */}
                    {/*  6.  PoA Number                                             */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> PoA Number </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.poanumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  7.  Agreement Type                                         */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Agreement Type  </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.agreementtype}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  8.  Vendor                                                 */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Vendor  </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '100%' }}
                            type='text'
                            value={task?.vendor?.vendorname}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  9. Lendlease Company (signing entity)                      */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Lendlease Company (signing entity) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.lendleasecompany}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  10. Parent Contract                                        */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Parent Contract </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.parentcontract}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  11. Contract Start Date                                    */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Contract Start Date </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.contractstartdate}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  12. Contract End Date                                      */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Contract End Date </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.contractenddate}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Financial Information                                */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'financialInformationCollapsed': !taskSection.financialInformationCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.financialInformationCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Financial Information</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.financialInformationCollapsed}></hr>

                {/* ------------- Financial Information ------------- */}

                <div className='p-[3%] pt-3' hidden={taskSection?.financialInformationCollapsed}>

                    {/* =========================================================== */}
                    {/*  13. Currency                                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Currency </label>
                        <select
                            className={'Input-Field-Select'}
                            value={formData?.currency}
                            style={{ width: '300px' }}
                            onChange={(e) => setFormData({ 'currency': e.target.value })}
                            disabled={task?.status === 'Completed'}
                        >
                            <option hidden value=''>-</option>
                            <option value='AUD'> AUD </option>
                            <option value='SGD'> SGD </option>
                            <option value='GBP'> GBP </option>
                            <option value='USD'> USD </option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  14. Estimated Annual Contract Value (exTax)                */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Estimated Annual Contract Value (exTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={formData?.estannualcontractvalue}
                            onChange={(e) => setFormData({ 'estannualcontractvalue': e.target.value })}
                            disabled={task?.status === 'Completed'}
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  15. Estimated Total Contract Value (exTax)                 */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Estimated Total Contract Value (exTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={formData?.esttotalcontractvalue}
                            onChange={(e) => setFormData({ 'esttotalcontractvalue': e.target.value })}
                            disabled={task?.status === 'Completed'}
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  16. PO Number (eFinance)                                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> PO Number (eFinance) <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={formData?.ponumber}
                            onChange={(e) => setFormData({ 'ponumber': e.target.value })}
                            disabled={task?.status === 'Completed'}
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  17. Setup PO Line Items                                    */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Setup PO Line Items </label>

                        {/*  PO Line Table */}
                        <div className='border border-[#D8D8D8] rounded'>
                            <table className='table-fixed w-full max-h-96 overflow-y-scroll'>
                                <thead>
                                    <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                                        <th className='py-[15px] px-[20px] font-medium text-sm'>Project Code</th>
                                        <th className='py-[15px] px-[8px] font-medium text-sm'>Task Number</th>
                                        <th className='py-[15px] px-[8px] font-medium text-sm'>Expenditure Type</th>
                                        <th className='py-[15px] px-[20px] font-medium text-sm'>Currency</th>
                                        <th className='py-[15px] px-[20px] font-medium text-sm'>PO Amount (exTax)</th>
                                        <th className='py-[15px] px-[20px] font-medium text-sm'>Tax rate (%)</th>
                                        <th className='py-[15px] px-[8px] font-medium text-sm'>Fiscal Year</th>
                                        <th className='py-[15px] px-[8px] font-medium text-sm'>Description</th>
                                        <th className='py-[15px] px-[20px] font-medium text-sm'>LoA 1</th>
                                        <th className='py-[15px] px-[8px] font-medium text-sm'>Operating Unit</th>

                                        <th className='py-[15px] px-[15px] font-medium text-sm min-w-20'>
                                            <Tooltip
                                                message={'+ Add PO item'}
                                                children={
                                                    <img className='cursor-pointer' src={task?.status === 'Completed' ? AddDisabled : Add} alt='add' onClick={() => task?.status === 'Completed' ? null : setAddPOLineItemPaneOpen(true)}></img>
                                                }
                                            ></Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        // No PO Line Items Found
                                        task?.polineitems?.length === 0 || task?.polineitems === undefined
                                            ?
                                            (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <div className='text-center p-[2%]'>
                                                            Click on the '+' icon to add a new PO Line Item.
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            (

                                                // Map in each PO Line Item
                                                task?.polineitems?.map((poLine, index) => (
                                                    <tr
                                                        key={index}
                                                        className='border-b-[#C8C8C9] border-solid border-b last:border-0 hover:bg-[#F0F7F7]'
                                                    >
                                                        {/* Project Code */}
                                                        <td className='py-[15px] px-[20px] text-sm'>{poLine?.projectcode}</td>

                                                        {/* Task Number */}
                                                        <td className='py-[15px] px-[8px] text-sm'>
                                                            {poLine?.tasknumber}
                                                        </td>

                                                        {/* Expenditure Type */}
                                                        <td className='py-[15px] px-[8px] text-sm'>{poLine?.expendituretype}</td>

                                                        {/* Currency */}
                                                        <td className='py-[15px] px-[20px] text-sm'>{poLine?.currency}</td>

                                                        {/* PO Amount */}
                                                        <td className='py-[15px] px-[20px] text-sm'>{poLine?.poamount}</td>

                                                        {/* Tax Rate */}
                                                        <td className='py-[15px] px-[20px] text-sm'>{poLine?.taxrate}</td>

                                                        {/* Fiscal Year */}
                                                        <td className='py-[15px] px-[8px] text-sm'>{poLine?.fiscalyear}</td>

                                                        {/* Description */}
                                                        <td className='py-[15px] px-[8px] text-sm'>{poLine?.description}</td>

                                                        {/* LoA 1 */}
                                                        <td className='py-[15px] px-[20px] text-sm'>{poLine?.loa1}</td>

                                                        {/* Operating Unit */}
                                                        <td className='py-[15px] px-[8px] text-sm'>{poLine?.operatingunit}</td>

                                                        {/* Dropdown with Edit and Remove options */}

                                                        {
                                                            task?.status === 'Completed' ? null :
                                                                (
                                                                    <td className='py-[15px] px-[8px] text-sm'>

                                                                        <KebabDropdown>

                                                                            <div className=' bg-white  rounded shadow-lg z-10'>

                                                                                <button
                                                                                    onClick={() => handlePOItemEditClick(index)}
                                                                                    className='block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100'
                                                                                >
                                                                                    Edit
                                                                                </button>

                                                                                <button
                                                                                    onClick={() => handlePOItemDeleteClick(index)}
                                                                                    className='block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100'
                                                                                >
                                                                                    Remove
                                                                                </button>

                                                                            </div>
                                                                        </KebabDropdown>
                                                                    </td>
                                                                )
                                                        }

                                                    </tr>
                                                ))
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Comments                                             */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'commentsCollapsed': !taskSection.commentsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.commentsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Comments</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.commentsCollapsed}></hr>

                {/* ------------- COMMENTS ------------- */}

                <div className='p-[3%] pt-4' hidden={taskSection?.commentsCollapsed}>
                    <CommentsThread
                        task={task}
                    ></CommentsThread>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Complete Button                                      */}
            {/* ===================================================== */}

            <div className='flex flex-col gap-1 mt-2 mb-0'>

                <button
                    className='Primary-Button w-fit'
                    onClick={handleCompleteClick}
                    disabled={task?.status === 'Completed' || formData.ponumber === ''}
                >
                    Complete
                </button>
            </div>

            {/* ===================================================== */}
            {/*  Add PO Line Item Pane                                */}
            {/* ===================================================== */}

            <AddPOLineItemPane
                task={task}
                addPOLineItemPaneOpen={addPOLineItemPaneOpen}
                setAddPOLineItemPaneOpen={setAddPOLineItemPaneOpen}
            ></AddPOLineItemPane>

            {/* ===================================================== */}
            {/*  Edit PO Line Item Pane                               */}
            {/* ===================================================== */}

            {
                editPOLineItemPaneOpen &&
                (
                    <EditPOLineItemPane
                        task={task}
                        editPOLineItemPaneOpen={editPOLineItemPaneOpen}
                        setEditPOLineItemPaneOpen={setEditPOLineItemPaneOpen}
                        polineitemIndex={selectedPOLineItemIndex}
                    ></EditPOLineItemPane>
                )
            }

            {/* ===================================================== */}
            {/*  Complete Request Modal                               */}
            {/* ===================================================== */}

            <CompleteRequestModal
                task={task}
                completeRequestModal={completeRequestModal}
                inProgressTasks={pendingTasks}
                setCompleteRequestModal={setCompleteRequestModal}
                confirmationChecked={confirmationChecked}
                setConfirmationChecked={setConfirmationChecked}
                closeTicket={closeTicket}
            ></CompleteRequestModal>

        </div>
    );

}
