//================================================================
//  Component: Assign Task
//================================================================

//  Purpose: This is an input field that allows you to search a list of LL users and assign them to a business request/task

//  Properties:
//    - query = {An array, this contains the query that filters the group of users you would like to search}
//    - currentRequest = {A useState, this contains the current business request object}
//    - styleInput = <OPTIONAL> {object, CSS style object}
//    - disabled = <OPTIONAL> {boolean, determines if the field is disabled}

//  Example:
//  <AssignTask
//      query={['roles.csmUser', '==', true]}
//      currentRequest={request}
//      styleInput={{ width: '400px' }}
//  ></AssignTask>

//================================================================


//Libraries
import React, { useContext, useReducer, useEffect } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';
import { SetToast } from '../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';
import QueryListener from '../../Library/QueryListener';

//Images

export default function AssignTask({
    query,
    currentRequest,
    styleInput, 
    disabled
}) {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'defaultUsers': [],
            'searchResults': [],
            'newUserInput': '',
            'newUserName': '',
            'queryMessage': '',
            'hideDropdown': true
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function used to handle search
    function handleSearch(value) {
        
        setSearchData({
            'searchResults': [],
            'newUserInput': value,
            'hideDropdown': false
        })

        // Validate input
        if (value.length === 0) {

            return setSearchData({ 
                'searchResults': searchData?.defaultUsers,
                'newUserInput': value,
                'hideDropdown': false, // Show Dropdown
                'queryMessage': ''
            });

        }

        // Filter by characters in email
        const filteredResults = searchData?.defaultUsers?.filter((user) => user?.searcharray?.includes(value.toLowerCase()))

        setSearchData({
            'searchResults': filteredResults,
            'queryMessage': filteredResults?.length > 0 ? `Showing ${filteredResults?.length} result(s)` : ''
        });

    }

    // Used to change the assigned to field
    function handleSave(user) {

        //=================================================
        //  Update useReducer
        //=================================================
  
        setSearchData({ 
            'searchResults': [],
            'newUserInput': user?.emailaddress,
            'newUserName': `${user?.givenname} ${user?.surname}`,
            'hideDropdown': true
        });
  
        //=================================================
        //  Update document in Firestore
        //=================================================

        const updatedDoc = {
            'assignedto': {
                'email': '',
                'givenname': '',
                'surname': ''
            },
            'assigneddate': new Date(),
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(),
            'activityfeed': currentRequest.activityfeed
        };

        // Handle Assignment to the Same User
        if (user?.emailaddress === currentRequest?.assignedto?.email) return;

        // Handle Unassigned 
        if (user?.emailaddress === 'Unassigned') {

            // Change Assigned To
            updatedDoc.assignedto = {
                'email': 'Unassigned',
                'givenname': '',
                'surname': ''
            }

            // Update Status
            updatedDoc.status = 'Not Started';
            
            // Update the activity feed
            if (currentRequest?.status !== 'Not Started') {

                updatedDoc.activityfeed.push({
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': currentRequest?.assignedto?.email,
                        'givenname': currentRequest?.assignedto?.givenname,
                        'surname': currentRequest?.assignedto?.surname
                    },
                    'action': 'unassigned',
                    'comments': '',
                })

            }

        // New User Assigned
        } else {
         
            // Change Assigned To
            updatedDoc.assignedto = {
                'email': user?.emailaddress,
                'givenname': user?.givenname,
                'surname': user?.surname
            }
            
            // Update Status
            updatedDoc.status = 'In Progress';

            // Update activity feed 
            updatedDoc.activityfeed.push({
                'activitydate': new Date(),
                'actionedby': {
                    'email': user?.emailaddress,
                    'givenname': user?.givenname,
                    'surname': user?.surname
                },
                'action': 'assigned',
                'comments': '',            
            })

        }

        return WriteDocument('tasks', currentRequest?.taskid, updatedDoc, true)
        .catch((error) =>{
          console.log('Error', error);
                      
            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to assign task',
            });
  
        })
  
    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------
    
    // Query Listener
    // - Get users
    useEffect(() => {
  
        if (query === null) return;
  
        function onLoadChange(documents){

            // Split email into searchable array
            const emailaddress = 'Unassigned';
            let previousValue;
            const emailArray = [];

            [...emailaddress].forEach((currentValue) => {

                // Create an array of each character combination
                emailArray.push(`${previousValue}${currentValue}`.toLowerCase());
                previousValue = `${previousValue}${currentValue}`.toLowerCase();
    
            });

            setSearchData({
                'newUserInput': currentRequest?.assignedto?.email,
                'newUserName': `${currentRequest?.assignedto?.givenname} ${currentRequest?.assignedto?.surname}`,
                'defaultUsers': [
                    {
                        'emailaddress': 'Unassigned',
                        'givenname': '',
                        'surname': '',
                        'searcharray': emailArray
                    }, 
                    ...documents
                ],
                'searchResults': [
                    {
                        'emailaddress': 'Unassigned',
                        'givenname': '',
                        'surname': '',
                        'searcharray': emailArray
                    }, 
                    ...documents
                ]
            });
  
        }
        
        function onError(error) {
    
          console.log(error);
        
        }
        
        const unsubscribe = QueryListener('users', [query,  ['usertype', '==', 'lendleaseuser']], onLoadChange, onLoadChange, onError);
        
        return () =>{
          unsubscribe();
        };
          
      // eslint-disable-next-line
      }, [query]);
      
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    id='email'
                    className='min-w-[250px] h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-[5px] cursor-pointer disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                    style={styleInput}
                    type='text'
                    placeholder='Search user by email address'
                    value={searchData?.newUserInput}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    onPaste={(evt) => handleSearch(evt.clipboardData.getData('email'))}
                    onClick={() => setSearchData({ 'hideDropdown': searchData?.newUserInput?.length === 0 ? false : !searchData?.hideDropdown })}
                    autoComplete='off'
                    disabled={disabled}
                ></input>

            </div>

            {/* Search Results */}
            <div 
                className='absolute z-10 top-11 min-w-[250px] max-h-[150px] overflow-y-auto overflow-x-hidden bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'
                style={styleInput}
                hidden={searchData?.hideDropdown}
            >

                {/* Results */}
                {
                    searchData?.searchResults?.length === 0 ?
                        <div className='font-medium text-[14px] px-3 py-[12px]'>
                            No results found.
                        </div>
                    :
                    searchData?.searchResults.map((user, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-3 py-[12px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => handleSave(user)}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 p-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                <p className='m-0 p-0 text-[14px]'>{user?.emailaddress}</p>
                            </div>
                        
                        </div>
                    ))
                }
                
                {/* Total */}
                <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage?.length === 0}>
                    {searchData.queryMessage}
                </div>

            </div>
            
        </div>
    )

  //------------------------------------------------------
}
