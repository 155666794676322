//================================================================
//  Page: Requests
//================================================================

//  Purpose: This page shows all requests at each stage

//================================================================


// Libraries
import React, { useReducer } from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import Unassigned from './Tabs/Unassigned';
import InProgress from './Tabs/InProgress';
import Completed from './Tabs/Completed';
import All from './Tabs/All';

// Functions

// Images

// CSS
import './Requests.css';


export default function Requests() {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store the total requests per stage/view
  const [requests, setRequests] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'unassignedRequests': [],
      'unassignedRequestsFiltered': [],
      'inProgressRequests': [],
      'inProgressRequestsFiltered': [],
      'completedRequests': [],
      'completedRequestsFiltered': [],
      'allRequests': [],
      'allRequestsFiltered': [],
    }
  );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      header={
        <div className='Page-Header-Container'>
          <p className='font-medium text-[20px] mb-0'> Requests </p>
        </div>
      }
      body={
        <TabView
          defaultView={1}

          //------------------------------------------------------
          //  Unassigned
          //------------------------------------------------------

          oneTitle={
            <div className='flex flex-row gap-2'>
              Unassigned
              <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.unassignedRequests?.length === 0}>
                {requests?.unassignedRequests?.length}
              </div>
            </div>
          }
          oneRoute={'/requests?view=unassigned'}
          oneContent={
            <Unassigned
              requests={requests}
              setRequests={setRequests}
            ></Unassigned>
          }

          //------------------------------------------------------
          //  In Progress
          //------------------------------------------------------

          twoTitle={
            <div className='flex flex-row gap-2'>
              In Progress
            </div>
          }
          twoRoute={'/requests?view=inprogress'}
          twoContent={
            <InProgress
              requests={requests}
              setRequests={setRequests}
            ></InProgress>
          }

          //------------------------------------------------------
          //  Completed
          //------------------------------------------------------

          threeTitle={'Completed'}
          threeRoute={'/requests?view=completed'}
          threeContent={
            <Completed
              requests={requests}
              setRequests={setRequests}
            ></Completed>
          }

          //------------------------------------------------------
          //  All
          //------------------------------------------------------

          fourTitle={'All'}
          fourRoute={'/requests?view=all'}
          fourContent={
            <All
              requests={requests}
              setRequests={setRequests}
            ></All>
          }

        ></TabView>
      }
    ></PageComponent>
  )
}
