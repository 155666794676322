//================================================================
//  Component: Triage Tab
//================================================================

//  Purpose: This contains the triage information for a Procurement request task

//  Properties:
//    - task = {An object, contains the Procurement request task details}

//  Example:
//    <TriageTab
//      task={task}
//    ></TriageTab>    

// ==============================================================

// Libraries
import { useReducer } from 'react';

// Contexts

// Components
import AssignTask from '../../../../Components/AssignTask/AssignTask';

//Functions
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';
import FileIcon from '../../../../Components/Images/Icon_File_Black.svg';

// CSS
import '../../Task.css'

export default function TriageTab({
    task
}) {

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'assignedToCollapsed': true,
            'digitalCommercialRequestCollapsed': true,
        }
    );

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* =========================================================== */}
            {/*  Status Label                                               */}
            {/* =========================================================== */}

            {
                task?.status === 'Completed' && (
                    <div className='px-4 py-3 mb-2 bg-[#E7F2DA] border-l-4 border-[#8ABB45]'>
                        <h6 className='text-black'>Completed</h6>
                        <p className='mb-0'>
                            <b className='font-medium'>
                                {task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}
                            </b> has completed this Procurement request on {ConvertDate(task?.lastmodifieddate)}.
                        </p>
                    </div>
                )
            }

            {/* ===================================================== */}
            {/*  Assigned To                                          */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'assignedToCollapsed': !taskSection.assignedToCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.assignedToCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'> Assigned To </h4>
                </div>
                <hr className='m-0' hidden={taskSection?.assignedToCollapsed}></hr>

                <div className='p-[3%]' hidden={taskSection?.assignedToCollapsed}>

                    {/* ===================================== */}
                    {/*  Form                                 */}
                    {/* ===================================== */}

                    {/* ==================================== */}
                    {/*  Assignee                            */}
                    {/* ==================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium mt-0'> Assignee </label>
                        {
                            task !== undefined &&
                            <AssignTask
                                query={['roles.commercialUser', '==', true]}
                                currentRequest={task}
                                styleInput={{
                                    width: '300px'
                                }}
                                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') ? true : false}
                            ></AssignTask>
                        }
                    </div>

                    {/* =========================================================== */}
                    {/*  Date Assigned                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row mb-2'>
                        <label className='font-medium'> Date Assigned </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            defaultValue={ConvertDate(task?.assigneddate)}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Digital Commercial Request                           */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'digitalCommercialRequestCollapsed': !taskSection.digitalCommercialRequestCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.digitalCommercialRequestCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Digital Commercial Request</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.digitalCommercialRequestCollapsed}></hr>

                {/* ------------- COMMERCIAL REQUEST FORM ------------- */}

                <div className='p-[3%]' hidden={taskSection?.digitalCommercialRequestCollapsed}>

                    {/* ------------- REQUEST INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mb-2'>Request Information</h5>

                    {/* =========================================================== */}
                    {/*  1. Request ID                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 1. Request ID </label>
                        <input
                            className='Input-Field-Text uppercase'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.requestid}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  2. PoA Number                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 2. PoA Number </label>
                        <input
                            className='Input-Field-Text uppercase'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.poanumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  3. Request Type                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 3. Request Type </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.digitalcommercialrequest?.requesttype}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  4. Application Name                                        */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 4. Application Name </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.digitalcommercialrequest?.applicationname}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  5. Short Description                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 5. Short Description </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            value={task?.digitalcommercialrequest?.shortdescription}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  6. Requester Name                                          */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 6. Requester Name </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.digitalcommercialrequest?.requestername}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  7. Region                                                  */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 7. Region </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.region}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  8. Sponsor Name                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 8. Sponsor Name </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.digitalcommercialrequest?.sponsorname?.email}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  9. Created By                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 9. Created By </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.createdby?.email}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  10. Supporting Documents                                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 10. Supporting Documents </label>
                        {
                            task?.digitalcommercialrequest?.supportingdocs?.length === 0 ?
                                (
                                    <div>
                                        No documents found.
                                    </div>
                                )
                                :
                                (
                                    <table className='table-auto w-full border-separate border-spacing-0 border-1 border-solid border-[#D8D8D8] rounded-[10px]'>
                                        <thead>
                                            <tr>
                                                <th className='font-normal px-3 py-2'>Uploaded Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                task?.digitalcommercialrequest?.supportingdocs?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td className='flex flex-row gap-2 px-3 py-2 text-[#0972D3] underline border-t border-t-[#D8D8D8]'>
                                                            <img src={FileIcon} alt='file'></img>
                                                            <a href={file?.fileUrl} target='_blank' rel='noreferrer'>
                                                                {file?.fileName}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                        }
                    </div>

                    {/* ------------- CONTRACT INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Contract Information</h5>

                    {/* =========================================================== */}
                    {/*  11. Commercial Scope                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium mb-0'> 11. Commercial Scope Description </label>
                        <textarea
                            className='Input-Field-TextArea'
                            value={task?.digitalcommercialrequest?.commercialscope}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  12. Buy / Sell Agreement                                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 12. Is this a buy or sell agreement? </label>
                        <select className='Input-Field-Select' style={{ width: '200px' }} disabled>
                            <option>{task?.digitalcommercialrequest?.buysellagreement}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  13. Agreement Type                                         */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 13. Agreement Type </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.digitalcommercialrequest?.agreementtype}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  14. Contract Start Date                                    */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 14. Contract Start Date </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.digitalcommercialrequest?.contractstartdate}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  15. Lendlease Company                                      */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 15. Lendlease Company (Signing Entity) </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.digitalcommercialrequest?.lendleasecompany}</option>
                        </select>
                    </div>

                    {/* ------------- OTHER PARTY INFORMATION (VENDOR/CUSTOMER) ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Other Party Information (Vendor / Customer) </h5>

                    {/* =========================================================== */}
                    {/*  16. Vendor Name                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 16. Vendor Name </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.digitalcommercialrequest?.vendor?.vendorname}
                            disabled
                        ></input>
                    </div>

                    {/* ------------- FINANCIAL INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Financial Information </h5>

                    {/* =========================================================== */}
                    {/*  17. Currency                                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 17. Currency </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.digitalcommercialrequest?.currency}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  18. Estimated Annual Contract Value (eTax)                 */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 18. Estimated Annual Contract Value (eTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.estannualcontractvalue}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  19. Estimated Total Contract Value (eTax)                  */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 19. Estimated Total Contract Value (eTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.esttotalcontractvalue}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  20. Project Code                                           */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 20. Project Code </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.projectcode}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  21. Task Number                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 21. Task Number </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.tasknumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  22. Expenditure Type                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 22. Expenditure Type </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.digitalcommercialrequest?.expendituretype}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>
        </div >
    );
}
