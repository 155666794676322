//================================================================
//  Component: Global Toast
//================================================================

//  Purpose: Generic Toast component with the useContext 'GetToast' & 'SetToast'

//  IMPORTANT: DO NOT change useContexts WITHOUT consulting the team.

//  Example:
//    <GlobalToast></GlobalToast>    

//  How to use:
//  
//  1. You need to import the useContext 'SetToast' and assign it to variable, as shown below
//     
//     import { SetToast } from '../../Library/GlobalContexts';

//     const setToast = useContext(SetToast); 
//    
//  2. Update the useContext with a type and message. Example of an error toast below.
//     
//     setToast({
//         'type': 'error',
//         'message': 'Failed to assign task',
//     });

//================================================================


//Libraries
import React, { useContext, useEffect } from 'react';

//Contexts
import { GetToast, SetToast } from '../../Library/GlobalContexts';

//Components

//Functions

//Images
import infoIcon from '../Images/Icon_InfoToast_Blue.svg';
import pendingIcon from '../Images/Icon_Loading_Green.svg';
import successIcon from '../Images/Icon_Approved_Green.svg';
import warningIcon from '../Images/Icon_WarningToast_Yellow.svg';
import errorIcon from '../Images/Icon_Error_Red.svg';
import closeIcon from '../Images/Icon_Clear_Grey.svg';


export default function GlobalToast() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getToast = useContext(GetToast);
    const setToast = useContext(SetToast);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // onChange
  //  1. If the type is success, hide after 10 seconds
  useEffect(() => {

    if (getToast?.type !== 'success') return;

    // 10 second delay
    setInterval(() => {

      setToast({
        'type': '',
        'message': '',
      });
  
    }, 20000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToast.type]);
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  //------------------------------------------------------
  //  If values are missing > Hide Toast
  //------------------------------------------------------

  if (getToast?.type === '') return null;
  if (getToast?.type === undefined) return null;
  if (getToast?.message === undefined) return null;
  if (getToast?.message === '') return null;

  //----------------------------------------------------------------------------
  //  Information Toast
  //  Purpose: Use this when you want to inform the user about an activity
  //----------------------------------------------------------------------------

  if (getToast.type === 'info') {

    return (
      <div className='flex flex-row items-center absolute z-50 bottom-10 right-10 bg-[#E1F4FF] px-[15px] py-[10px] rounded-md gap-3 max-w-[50%] font-medium shadow-lg'>
        <img className='w-[25px] h-[25px]' src={infoIcon} alt='Info-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] text-wrap cursor-default'>{getToast?.message}</span>
        <img className='w-[20px] cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  //----------------------------------------------------------------------------
  //  Pending Toast
  //  Purpose: Use this to notify the user when an operation they have performed is in progress. 
  //           (Do not use unless the operation has to be performed outside the context of the page, e.g. adding a new record to a table, uploading a file, etc.)
  //----------------------------------------------------------------------------  
  
  if (getToast.type === 'pending') {
    return (
      <div className='flex flex-row items-center absolute z-50 bottom-10 right-10 bg-[#E1F4FF] px-[15px] py-[10px] rounded-md gap-3 max-w-[50%] font-medium shadow-lg'>
        <img className='animate-spin' src={pendingIcon} alt='Pending-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] text-wrap cursor-default'>{getToast?.message}</span>
        <img className='w-[20px] cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  //----------------------------------------------------------------------------
  //  Success Toast
  //  Purpose: Use this when you want to notify the user than an operation they have performed has completed/succeeded.
  //---------------------------------------------------------------------------- 

  if (getToast.type === 'success') {

    return (
      <div className='flex flex-row items-center absolute z-50 bottom-10 right-10 bg-[#E7F2DA] px-[15px] py-[10px] rounded-md gap-3 max-w-[50%] font-medium shadow-lg'>
        <img className='w-[25px] h-[25px]' src={successIcon} alt='Success-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] text-wrap cursor-default'>{getToast?.message}</span>
        <img className='w-[20px] cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  //----------------------------------------------------------------------------
  //  Warning Toast
  //  Purpose: Use this when you want to warn the user prior to them performing an action.
  //---------------------------------------------------------------------------- 

  if (getToast.type === 'warning') {

    return (
      <div className='flex flex-row items-center absolute z-50 bottom-10 right-10 bg-[#FBF6D9] px-[15px] py-[10px] rounded-md gap-3 max-w-[50%] font-medium shadow-lg'>
        <img className='w-[25px] h-[25px]' src={warningIcon} alt='Warning-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] text-wrap cursor-default'>{getToast?.message}</span>
        <img className='w-[20px] cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  //----------------------------------------------------------------------------
  //  Error Toast
  //  Purpose: Use this when you want to notify the user when an operation they have performed has failed, e.g failed to upload file or save a record.
  //----------------------------------------------------------------------------

  if (getToast.type === 'error') {

    return (
      <div className='flex flex-row items-center absolute z-50 bottom-10 right-10 bg-[#FAE1E5] px-[15px] py-[10px] rounded-md gap-3 max-w-[50%] font-medium shadow-lg'>
        <img className='w-[25px] h-[25px]' src={errorIcon} alt='Error-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] text-wrap cursor-default'>{getToast?.message}</span>
        <img className='w-[20px] cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

}
