//================================================================
//  Component: Kebab Dropdown
//================================================================

//  Purpose: This is a kebab menu with a dropdown menu

//  Properties:
//    - children = {HTML, this is the content for menu}

//  Example:
//    <KebabDropdown
//      children={<div></div>}
//    ></KebabDropdown>    

//================================================================


//Libraries
import React, { useState, useRef, useEffect } from 'react';

//Contexts

//Components

//Functions

//Images
import Kebab from '../Images/Icon_Kebab_Black.svg';

//CSS

export default function KebabDropdown({
  children
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to toggle visibility of the kebab dropdown menu 
  const [showKebabDropdown, setShowKebabDropdown] = useState(false);

  //------------------------------------------------------
  //  Refs
  //------------------------------------------------------

  // Ref for the dropdown menu
  const dropdownRef = useRef(null);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Close the dropdown when the user clicks outside the component
  const handleClickOutside = (event) => {

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

      // Close dropdown when clicking outside
      setShowKebabDropdown(false);

    }

  };

  //Close the dropdown when the user clicks inside the component
  const handleClickInside = () => {

    // Close dropdown when clicking inside the dropdown itself
    setShowKebabDropdown(false);

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Add the event listener for detecting clicks outside the dropdown
  useEffect(() => {

    if (showKebabDropdown) {

      document.addEventListener('mousedown', handleClickOutside);

    } else {

      document.removeEventListener('mousedown', handleClickOutside);
      
    }

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [showKebabDropdown]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='relative' ref={dropdownRef}>

      {/* Kebab Icon */}
      <img
        className='cursor-pointer'
        src={Kebab}
        alt='kebab'
        onClick={() => setShowKebabDropdown((prev) => !prev)}
      ></img>

      {/* Dropdown Menu */}
      {
        showKebabDropdown && (
          <div
            className='flex flex-col absolute right-2 top-6 w-[150px] bg-white shadow-md z-10 border border-[#D8D8D8]'
            onClick={handleClickInside}
          >
            {children}
          </div>
        )
      }
    </div>
  );
}