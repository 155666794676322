//================================================================
//  Component: Complete Request Modal
//================================================================

//  Purpose: This modal asks the user to confirm they would like to close a ticket despite there being pending approvals

//  Properties:
//    - task = {An object, contains the procurement task details}
//    - completeRequestModal = {useState, used to store the current pending csm tasks}
//    - inProgressTasks = {useState, used to store the current second round in progress tasks}
//    - setCompleteRequestModal = {useState, used to toggle the visibility of the complete request modal}
//    - confirmationChecked = {useState, used to determine if the user has accepted that they will close the ticket despite there being pending tasks}
//    - setConfirmationChecked = {useState, used to set the checkbox state}
//    - closeTicket = {function, used to close the ticket}

//  Example:
//    <CompleteRequestModal
//      task={task}
//      completeRequestModal={completeRequestModal}
//      inProgressTasks={inProgressTasks}
//      setCompleteRequestModal={setCompleteRequestModal}
//      confirmationChecked={confirmationChecked}
//      setConfirmationChecked={setConfirmationChecked}
//      closeTicket={closeTicket}
//    ></CompleteRequestModal> 

//================================================================

//Libraries
import React, { useState } from 'react';

//Contexts

//Components

//Functions

//Images
import Error from '../../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Warning from '../../../../../Components/Images/Icon_Warning_Blue.svg';

export default function CompleteRequestModal({
  task,
  completeRequestModal,
  inProgressTasks,
  setCompleteRequestModal,
  confirmationChecked,
  setConfirmationChecked,
  closeTicket
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set modal status > 'pending', 'onload', 'error'
  const [modalStatus, setModalStatus] = useState('onload');

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
  if (completeRequestModal === false) return null;

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (modalStatus === 'onload') {
    return (
      <div className='Modal-Background'>

        {/* Modal Container */}
        <dialog className='Modal-Container'>

          {/* Header */}
          <div className='flex flex-row gap-3 items-center'>
            <img src={Warning} alt='Warning'></img>
            <h4>In Progress task(s)</h4>
          </div>

          {/* Body */}
          <p className='max-w-[450px] mt-2 mb-3'>
            The below task(s) still in progress:
          </p>

          <ul>
            {
              inProgressTasks?.map((task, index) => (
                <li key={index} className='list-disc'>
                  {task?.tasktype}
                </li>
              ))
            }
          </ul>

          {/* Checkbox */}
          <div className='flex flex-row items-start gap-2 mb-2'>

            <label className='Checkbox-Container'>
              <input
                required
                type='checkbox'
                name='confirmation'
                checked={confirmationChecked}
                onChange={() => setConfirmationChecked(!confirmationChecked)}
              ></input>
              <span className='Checkbox'></span>
            </label>

            <label className='max-w-[400px]'>
              I acknowledge the task(s) still in progress.
              I wish to endorse this business request on the behalf of the Procurement team. <span className='text-[#C4314B]'>*</span>
            </label>

          </div>

          {/* Buttons */}
          <div className='flex flex-row gap-2'>

            <button className='Primary-Button' disabled={!confirmationChecked} onClick={() => {
              setCompleteRequestModal(false);
              closeTicket()
            }}
            >
              Proceed
            </button>

            <button className='Secondary-Button' onClick={() => setCompleteRequestModal(false)}>
              Cancel
            </button>

          </div>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (modalStatus === 'error') {
    return (
      <div className='Modal-Background'>

        {/* Modal Container */}
        <dialog className='Warning-Modal-Container justify-center items-center'>

          <img className='my-1 w-[50px]' src={Error} alt='Error'></img>

          <h4 className='mb-0'>Oops! Something went wrong.</h4>

          <p className='text-center leading-[1.7]'>
            An error occurred while we processed your request.
          </p>

          <button
            className='Warning-Button'
            onClick={() => {
              setModalStatus('onload');
              setCompleteRequestModal(false);
            }}
          >
            Close
          </button>

        </dialog>

      </div>
    )
  }

  //------------------------------------------------------
}