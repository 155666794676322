//================================================================
//  Component: RequestPane
//================================================================

//  Purpose: This side pane allows a user to view a Procurement Request

//  Properties:
//    - paneOpen = {useState, holds a boolean value that opens and closes the sidePane}
//    - setPaneOpen = {useState, updates the boolean value that opens and closes the sidePane}
//    - selectedRequest = {useState, stores the currently selected request}
//    - setSelectedRequest = {useState, used to set the selected request }

//  Example:
//  
//    <RequestPane
//      paneOpen={openPane}
//      setPaneOpen={setOpenPane}
//      selectedRequest={selectedRequest}
//      setSelectedRequest={setSelectedRequest}
//    ></RequestPane>

//================================================================

// Libraries
import React from 'react';

// Contexts

// Functions
import ConvertDate from '../../Library/ConvertDate';

// Images

export default function RequestPane({
    paneOpen,
    setPaneOpen,
    selectedRequest,
    setSelectedRequest
}) {

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

    // ==================================================
    //  Hide Side Pane
    // ==================================================

    if (!paneOpen) return null;

    // ==================================================
    //  Show Side Pane  
    // ==================================================

    return (
        <div className='Pane-Background'>
            <dialog className='Pane-Container' style={{ width: '80vw'}}>
                <div className='flex flex-col gap-3 justify-between'>

                    {/* ===================================== */}
                    {/*  Procurement Request Form             */}
                    {/* ===================================== */}

                    <div className='Request-Form-Container'>

                        {/* ===================================== */}
                        {/*  Header                               */}
                        {/* ===================================== */}

                        <h4 className='px-[5%] py-[2.5%] mb-0 text-[20px]'> Procurement Request | {selectedRequest?.requestid.toUpperCase()} </h4>
                        <hr className='m-0'></hr>

                        {/* ===================================== */}
                        {/*  Form                                 */}
                        {/* ===================================== */}

                        <div className='px-[5%] py-[4%]'>

                            {/* ------------- PO Request Information ------------- */}

                            <h5 className='text-[18px] text-teal-600 mb-2'>PO Request Information</h5>

                            {/* =========================================================== */}
                            {/*  Request Type                                               */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Request Type</label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.requesttype}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Name of Requester                                          */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Name of Requester </label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.requestername}
                                    disabled
                                ></input>
                            </div>

                            {/* ================================================================ */}
                            {/*  Short Description of Request *                                  */}
                            {/* ================================================================ */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Short Description of Request <span className='text-[#C4314B]'>*</span> </label>
                                <textarea
                                    className='Input-Field-TextArea'
                                    type='text'
                                    value={selectedRequest?.taskdescription}
                                    disabled
                                ></textarea>
                            </div>

                            {/* ============================================================================ */}
                            {/*  Detailed Request / Scope Description *                                      */}
                            {/* ============================================================================ */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Detailed Request / Scope Description <span className='text-[#C4314B]'>*</span></label>
                                <textarea
                                    className='Input-Field-TextArea'
                                    type='text'
                                    value={selectedRequest?.commercialscope}
                                    disabled
                                ></textarea>
                            </div>

                            {/* =========================================================== */}
                            {/*  Name of Sponsor *                                          */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Name of Sponsor <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.sponsorname}
                                    disabled
                                ></input>
                            </div>

                            {/* ------------- Contract Information------------- */}

                            <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Contract Information</h5>

                            {/* ========================================================================= */}
                            {/* PoA Number                                                                */}
                            {/* ========================================================================= */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> PoA Number </label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.poanumber}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Agreement Type *                                           */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Agreement Type <span className='text-[#C4314B]'>*</span></label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.agreementtype}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  Vendor *                                                   */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Vendor <span className='text-[#C4314B]'>*</span> </label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.vendor?.vendorname}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Lendlease Company (signing entity) *                       */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Lendlease Company (signing entity) <span className='text-[#C4314B]'>*</span></label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.lendleasecompany}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  Parent Contract                                            */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Parent Contract</label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.parentcontract}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  Contract Start Date *                                      */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Contract Start Date <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className='Input-Field-Text'
                                    type='date'
                                    value={selectedRequest?.contractstartdate}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Contract End Date  *                                       */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Contract End Date <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className='Input-Field-Text'
                                    type='date'
                                    value={selectedRequest?.contractenddate}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Document Storage Link*                                     */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Document Storage Link <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={selectedRequest?.documentstoragelink}
                                    disabled
                                ></input>
                            </div>

                            {/* ------------- Financial Information-------------*/}

                            <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Financial Information</h5>


                            {/* =========================================================== */}
                            {/*  Currency                                                   */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Currency</label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.currency}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  Estimated Annual Contract Value (exTax)                    */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Estimated Annual Contract Value (exTax)</label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.estannualcontractvalue}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  Estimated Total Contract Value (exTax)                     */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Estimated Total Contract Value (exTax)</label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.esttotalcontractvalue}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  PO Number (eFinance)                                       */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>PO Number (eFinance)</label>
                                <select className='Input-Field-Select' disabled>
                                    <option>{selectedRequest?.ponumber}</option>
                                </select>
                            </div>

                            {/* =========================================================== */}
                            {/*  PO Line Items table                                        */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>PO Line Items</label>
                            </div>
                            <div className='table-auto w-full border-separate border-spacing-0 border-1 border-solid border-[#D8D8D8] rounded-[10px]'>

                                <table className='w-full max-h-96 overflow-y-scroll'>
                                    <thead>
                                        <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                                            <th className='py-[15px] px-[20px] font-medium'>eFinance Project Code</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Task Number</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Expenditure Type</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Currency</th>
                                            <th className='py-[15px] px-[20px] font-medium'>PO Amount (exTax)</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Tax Rate (%)</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Fiscal Year</th>
                                            <th className='py-[15px] px-[20px] font-medium'>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            // No requests found
                                            selectedRequest?.polineitems?.length === 0 ? (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className='text-center p-[2%]'>
                                                            No PO Line Items Found
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (

                                                // Display PO Line Items
                                                selectedRequest?.polineitems?.map((poLine, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border-b-[#C8C8C9] border-solid border-b last:border-0 hover:bg-[#F0F7F7]"
                                                    >

                                                        {/* eFinance Project Code   */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.projectcode}</td>

                                                        {/* Task Number */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.tasknumber}</td>

                                                        {/* Expenditure Type */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.expendituretype}</td>

                                                        {/* Currency */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.currency}</td>

                                                        {/* PO Amount (exTax) */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.poamount}</td>

                                                        {/* Tax Rate (%) */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.taxrate}%</td>

                                                        {/* Fiscal Year */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.fiscalyear}</td>

                                                        {/* Description */}
                                                        <td className="py-[15px] px-[20px]">{poLine?.description}</td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* =========================================================== */}
                            {/*  Created By                                                 */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Created By </label>
                                <input
                                    className='Input-Field-Text'
                                    style={{ width: '300px' }}
                                    type='text'
                                    value={selectedRequest?.createdby?.email}
                                    disabled
                                ></input>
                            </div>

                            {/* =========================================================== */}
                            {/*  Created Date                                               */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Created Date </label>
                                <input
                                    className='Input-Field-Text'
                                    style={{ width: '300px' }}
                                    type='text'
                                    value={ConvertDate(selectedRequest?.createddate)}
                                    disabled
                                ></input>
                            </div>

                        </div>

                    </div>

                    {/* ===================================== */}
                    {/*  Close Button                         */}
                    {/* ===================================== */}

                    <div className='flex flex-row gap-2'>
                        <button className='Primary-Button' onClick={() => {

                            // Reset Selected Request
                            setSelectedRequest();

                            // Close Side Pane
                            setPaneOpen(false);

                        }}>
                            Close
                        </button>
                    </div>

                </div>
            </dialog>
        </div>
    );
}
