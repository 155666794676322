//================================================================
//  Tab: Completed
//================================================================

//  Purpose: This tab displays all the Procurement requests that have been completed

//  Properties:
//    - requests = {An array (useState) - this is the list of all the Procurement requests that have been completed}
//    - setRequests = {useState, used to set the requests state}

//  Example:
//    <Completed
//      requests={requests}
//      setRequests={setRequests}
//    ></Completed>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';

//Functions
import QueryListener from '../../../Library/QueryListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

export default function Completed({
  requests,
  setRequests
}) {

  //------------------------------------------------------
  //  useContext and React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Handles search function
  function handleSearch(value) {

    setSearchInput(value);

    // Reset the Filter
    if (value.length === 0) {
      setRequests({ 'completedRequestsFiltered': requests.completedRequests });

    };

    // Search Filter
    //  1. Request ID
    //  2. Short Description
    //  3. Assigned To
    const searchResults = requests.completedRequests.filter((object) =>
      object?.requestid?.toLowerCase().includes(value.toLowerCase()) ||
      object?.shortdescription?.toLowerCase().includes(value.toLowerCase()) ||
      object?.assignedto?.email?.toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setRequests({ 'completedRequestsFiltered': searchResults });

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Procurement requests that are completed
  useEffect(() => {

    function onLoadChange(documents) {

      // Sort by Created Date (Newest to Oldest)
      documents.sort(function (a, b) {
        return b.createddate - a.createddate;
      });

      setRequests({ 'completedRequests': documents });
      setRequests({ 'completedRequestsFiltered': documents });

    }

    function onError(error) {
      console.log(error);
      setPageStatus('error-fatal');
    }

    const unsubscribe = QueryListener('tasks', [
      ['tasktype', '==', 'Procurement'],
      ['assignmentgroup', '==', 'Procurement'],
      ['status', '==', 'Completed']
    ], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col px-[15px] gap-[20px]'>

          {/* ----------------------------------- */}
          {/*  Export                             */}
          {/* ----------------------------------- */}

          <div className='flex flex-row justify-end items-center gap-3'>

            {/* Download Report */}
            <ExportToCSV
              filename={`Completed-Requests-${Date.now()}`}
              data={requests?.completedRequestsFiltered}
            ></ExportToCSV>

          </div>

          {/* ----------------------------------- */}
          {/*  Requests Table                     */}
          {/* ----------------------------------- */}

          <div className='Table-Container'>

            {/* ======== Sorting and Filters ========== */}
            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {requests?.completedRequestsFiltered.length} of {requests?.completedRequests.length}
              </label>

              {/* Search Bar */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>

                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[40px] p-0 m-0 outline-none'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                  ></input>
                </label>
              </div>

            </div>

            <table className='w-full max-h-96 overflow-y-scroll'>
              <thead>
                <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                  <th className='py-[15px] px-[20px] font-medium'>Request ID</th>
                  <th className='py-[15px] px-[20px] font-medium'>Created</th>
                  <th className='py-[15px] px-[20px] font-medium'>Requester</th>
                  <th className='py-[15px] px-[20px] font-medium'>Short Description</th>
                  <th className='py-[15px] px-[20px] font-medium'>Vendor</th>
                  <th className='py-[15px] px-[20px] font-medium'>Assignment Group</th>
                  <th className='py-[15px] px-[20px] font-medium'>Last Modified</th>
                  <th className='py-[15px] px-[20px] font-medium'>Assigned To</th>
                </tr>
              </thead>
              <tbody>
                {
                  // No requests found
                  requests?.completedRequestsFiltered?.length === 0 ?
                    (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-[2%]'>
                            No requests found.
                          </div>
                        </td>
                      </tr>
                    )
                    :
                    (
                      // Map in each Procurement request
                      requests?.completedRequestsFiltered?.map((request, index) => (
                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0 hover:bg-[#F0F7F7]'>

                          {/* Request ID */}
                          <td
                            className='py-[15px] px-[20px] font-medium uppercase cursor-pointer hover:underline'
                            onClick={() => navigate(`/requests/${request?.taskid}`)}
                          >
                            {request?.requestid}
                          </td>

                          {/* Created Date */}
                          <td className='py-[15px] px-[20px]'>{ConvertDate(request?.createddate)}</td>

                          {/* Requester Name */}
                          <td className='py-[15px] px-[20px] capitalize'>
                            {request?.createdby?.givenname} {request?.createdby?.surname}
                          </td>

                          {/* Short Description */}
                          <td className='py-[15px] px-[20px]'>
                            <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                              {request?.taskdescription}
                            </div>
                          </td>

                          {/* Vendor  */}
                          <td className='py-[15px] px-[20px]'>{request?.vendor?.vendorname}</td>

                          {/* Assignment Group  */}
                          <td className='py-[15px] px-[20px]'>{request?.assignmentgroup}</td>

                          {/* Last Modified	 */}
                          <td className='py-[15px] px-[20px]'>{ConvertDate(request?.lastmodifieddate)}</td>

                          {/* Assigned To */}
                          <td className='py-[15px] px-[10px]'>
                            <input
                              className='w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8]'
                              value={request?.assignedto?.email}
                              style={{ width: '250px' }}
                              disabled>
                            </input>
                          </td>

                        </tr>
                      ))
                    )
                }
              </tbody>
            </table>

          </div>

        </div>
      }
    ></PageComponent>
  )
}