//================================================================
//  Component: Comments Thread
//================================================================

//  Purpose: This component handles the comment thread in the PO tab

//  Properties:
//  - task = {object, contains the procurment task details}

//  Example:
//    <CommentsThread
//      task={task}
//     ></CommentsThread>

//================================================================


//Libraries
import React, { useReducer, useContext, useState } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import UploadFile from '../../../../Library/UploadFile';
import AddRemoveDocumentArray from '../../../../Library/AddRemoveDocumentArray';

//Images
import FileIcon from '../../../../Components/Images/Icon_File_Black.svg';
import Loading from '../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS


export default function CommentsThread({
  task
}) {
    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the comments thread > 'pending', 'onload'
    const [commentsStatus, setCommentsStatus] = useState('onload')

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store comments
    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'newComment': '',
            'documents': [],
            'documentsErrorMessage': '',
            'formErrorMessage': ''
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function to post a new comment
    function postComment() {

        setCommentsStatus('pending');

        const filePromises = [];

        // Loop through files > Upload File
        formData.documents.forEach((document) => {

            filePromises.push(
                UploadFile(`tasks/${task?.taskid}/${document?.fileId}`, document?.fileObject),
            );

        });

        // Resolve Promises
        Promise.all(filePromises)
        .then((urls) => {

            const updatedDocs = [];

            // Loop through docs > Add URL
            formData?.documents.forEach((document, index) => {

                updatedDocs.push({
                    'fileUrl': urls[index],
                    'fileId': document?.fileId,
                    'fileName': document?.fileName,
                    'fileType': document?.fileType
                })

            })

            const commentId = `cm-${Date.now().toString()}${Math.floor(Math.random() * (9 - 10 + 1) + 10)}`;

            const commentObject = {
                'commentid': commentId,
                'message': formData?.newComment, 
                'createddate': new Date(),
                'createdbyemail': getUser?.emailaddress,
                'createdbyname': `${getUser?.givenname} ${getUser?.surname}`,
                'files': updatedDocs
            }

            return AddRemoveDocumentArray('tasks', task?.taskid, 'comments', commentObject, 'add')
            .then(() => {
    
                // Reset useReducer
                setFormData(
                  {
                    'newComment': '',
                    'documents': [],
                    'documentsErrorMessage': '',
                    'formErrorMessage': ''
                  }
                )

                setCommentsStatus('onload');
      
            })

        })
        .catch((error) => {
            console.log(error)
            setFormData({ formErrorMessage: 'Oops! Something went wrong! Please try again!' })

        })
  
    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    //======================================================
    //  Pending
    //======================================================

    if (commentsStatus === 'pending') {
        return (
            <div className='flex justify-center items-center text-center'>
                <img alt='loading-circle-icon' src={Loading}></img>
            </div>
        )
    }

    //======================================================
    //  Onload
    //======================================================

    else {
        return (
            <div className='flex flex-col gap-2'>
    
                {/* Comments Field */}
                <div className='FormComponent-Row'>
                    <label className='font-medium mt-0'> Add Comment </label>
                    <textarea
                        className='Input-Field-TextArea'
                        type='text'
                        placeholder='Type a comment'
                        value={formData?.newComment}
                        onChange={(e) => setFormData({ 'newComment': e.target.value })}
                        disabled={task?.status === 'Completed'}
                    ></textarea>
                </div>
    
                {/* Attach Files */}
                <div className='FormComponent-Row'>
                    <label className='font-medium mb-1'> Attach Documents </label>
                    
                    {/* File Upload */}
                    <div className='Attach-File-Onload-Container'>
                        
                        <input
                            className={formData?.documentsErrorMessage?.length > 0 ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                            style={{padding: '0px'}}
                            type='file'
                            accept='.pdf,.doc,.txt,.xlsx,.docx,.csv,.png,.jpg'
                            onChange={(evt) => {
    
                                const fileObject = evt.target.files[0];
                                    
                                // Validate File Types
                                const allowedFileTypes = ['pdf', 'doc', 'txt', 'xlsx', 'docx', 'csv', 'png', 'jpg'];
    
                                let fileType;
                                fileType = fileObject?.name.split('.');
                                fileType = fileType[fileType?.length - 1].toLowerCase();
    
                                if (allowedFileTypes.includes(fileType) === false) {
                                    return setFormData({ 'documentsErrorMessage': `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}.` });
                        
                                }
    
                                // Validate File Size < 20 MB
                                if (fileObject.size > 20000000) {
                                    return setFormData({ 'documentsErrorMessage': 'File size too large. Please upload a file smaller than 20MB.' });
    
                                }
    
                                // Update Form Data
                                const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`;
    
                                setFormData({
                                    'documents': [
                                        ...formData.documents,
                                        {
                                            'fileUrl': '',
                                            'fileId': fileId,
                                            'fileName': fileObject.name,
                                            'fileObject': fileObject,
                                            'fileType': fileType
                                        }
                                    ],
                                    'documentsErrorMessage': ''
                                });
                                
                                // Clear File Input
                                evt.target.value = '';
    
                            }}
                            disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') ? true : false}
                        ></input>
    
                    </div>
    
                    {/* Error Message */}
                    <label className='font-medium text-[#DE0000]' hidden={formData?.documentsErrorMessage.length === 0}>{formData?.documentsErrorMessage}</label>
    
                    {/* Uploads Table */}
                    {
                        formData?.documents.length > 0 &&
                        <div className='w-full flex flex-col text-[#0972D3] rounded-md underline border border-[#D8D8D8] mt-1'>
                            {
                                formData?.documents.map((doc, index) => (
                                <div key={index} className='flex flex-row justify-between items-center border-b border-b-[#D8D8D8] last:border-0 px-3 py-[2px]'>
            
                                    {/* File Name */}
                                    <div className='flex flex-row gap-2 items-center'>
                                        <img src={FileIcon} alt='file'></img>
                                        <p className='mb-0'>{doc?.fileName}</p>
                                    </div>
                                    
                                    {/* Delete Button */}
                                    <div className='Cancel-Icon min-w-[30px] mt-[5px] mb-0 p-0' onClick={() => setFormData({ 'documents': formData.documents.filter(item => item!== doc) })}></div>
            
                                </div>
                                ))
                            }           
                        </div>
                    }
    
                </div>
    
                {/* Buttons */}
                <button className='Primary-Button w-fit mx-1 mt-3 mb-2' disabled={formData.newComment?.length === 0 || task?.status === 'Completed'} onClick={() => postComment()}>
                    Post
                </button>
    
                {/* Comments Thread */}
                {
                    task?.comments?.length > 0 &&
                    <div className='flex flex-col'>
                        <hr></hr>
                        {
                            task?.comments.map((comment, index) => (
    
                            <div key={index} className='grid grid-cols-[60px_1fr] gap-0 p-3 rounded-md shadow-sm bg-white border border-[#D8D8D8] my-2'>
    
                                {/* User's Initials */}
                                <div className='w-fit h-fit p-2 m-0 rounded-[50%] font-medium text-[#00A7A4] border border-[#00A7A4]'>
                                    {comment?.createdbyname.split(' ')[0][0].toUpperCase()}{comment?.createdbyname.split(' ')[1][0].toUpperCase()}
                                </div>
    
                                {/* User's Comment */}
                                <div className='flex flex-col gap-[5px] text-[#545454]'>
                                    <p className='text-[#555] font-medium m-0'>{comment?.createdbyname}</p>
                                    <label className='text-[13px] text-[#737373] mb-[10px]'>
                                        {comment?.createddate?.toDate()?.toLocaleString('en-US', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}
                                    </label>
    
                                    {comment?.message}
                                    
                                    <div className='flex flex-col'>
                                        {
                                            comment?.files?.length > 0 &&
                                            comment?.files?.map((file, index) => (
                                                <div key={index} className='Comment-Box-File-Link mb-0'>
                                                    <a href={file.fileUrl} target='_blank' rel='noreferrer'>{file.fileName}</a>
                                                </div>
                                            ))
                                            
                                        }
                                    </div>
    
                                </div>
                                
                            </div>
                            ))
                        }
                    </div>
                }
    
                {/* Error Message */}
                <label className='font-medium text-[#DE0000] ml-[5px]' hidden={formData?.formErrorMessage.length === 0}>{formData?.formErrorMessage}</label>
    
            </div>
        )
    };

}
