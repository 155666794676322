//================================================================
//  Page: Task
//================================================================

//  Purpose: This displays all the details relating to a business request task

//================================================================

// Libraries
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadCrumbs';
import TabViewByStage from './Components/TabViewByStage';
import TriageTab from './Components/TriageTab/TriageTab';
import POTab from './Components/POTab/POTab';
import ActivityFeed from '../../Components/ActivityFeed/ActivityFeed';

// Functions
import DocumentListener from '../../Library/DocumentListener';
import ConvertDate from '../../Library/ConvertDate';
import QueryListener from '../../Library/QueryListener';

// Images

// CSS
import './Task.css'

// Images

export default function Task() {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

        const routerUseLocation = useLocation();
        const params = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        // Used to save the page status > 'pending', 'onload', 'success', 'error-fatal'
        const [pageStatus, setPageStatus] = useState('onload');

        // Holds the current task
        const [task, setTask] = useState();

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

        // Document Listener
        // - Find the task document
        useEffect(() => {

            const taskid = params.id;

            if (taskid === '') return;
            if (taskid === null) return;

            function onLoad(document){
                setTask(document);
                setPageStatus('onload');       

            }
            
            function onChange(document){
                setTask(document);
                setPageStatus('onload');       
    
            }
            
            function onError(error){
                console.log('error', error)
                setPageStatus('error-fatal');

            }
            
            const unsubscribe = DocumentListener('tasks', taskid, onLoad, onChange, onError);
            
            return () =>{
                unsubscribe();
            };


        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // Query Listener
        // - Get all the commercial users
        useEffect(() => {
            
            function onLoadChange(document){
    
                // Sort users in alphabetical order by email address 
                document.sort(function(a, b) {
                    var textA = a.emailaddress.toUpperCase();
                    var textB = b.emailaddress.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            
            }
            
            function onError(error){

                console.log(error);
                setPageStatus('error-fatal');

            }
            
            const unsubscribe = QueryListener('users', [['roles.commercialUser', '==', true]], onLoadChange, onLoadChange, onError);
            
            return () =>{
                unsubscribe();
            };
            
        // eslint-disable-next-line
        }, [pageStatus])
        
    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

        return (
            <PageComponent
                header={
                    <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
                        
                        {/* Breadcrumbs */}
                        <BreadCrumbs
                            rootLinkName={routerUseLocation.pathname.split('/')[1] === 'requests' ? 'Requests' : 'My Tasks'}                    
                            rootLinkRoute={`/${routerUseLocation.pathname.split('/')[1]}`}
                            childLinkName={task?.requestid.toUpperCase()}
                        ></BreadCrumbs>    


                        {/* Heading */}
                        <div className='flex flex-col w-fit-content'>
                            <div className='flex flex-row gap-3 text-[20px] font-medium m-0 p-0 text-wrap'>

                                {task?.applicationname}

                                {/* Status Label */}
                                {
                                    [
                                        {'name': 'Not Started', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Not Started'},
                                        {'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress'}, 
                                        {'name': 'Completed', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed'},
                                        {'name': 'Rejected', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Rejected'}, 
                                        {'name': 'Cancelled', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Cancelled'}, 

                                    ].filter((style) => style?.status === task?.status).map((object, index) => (
                                    <label key={index} className='Status-Label' style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}} >
                                        {object.name}
                                    </label>
                                    ))
                                }

                            </div>
                            <p className='mt-2 text-base text-wrap m-0'>
                                This request was submitted by <b className='font-medium capitalize'>{task?.createdby?.givenname} {task?.createdby?.surname}</b> on {ConvertDate(task?.createddate)}.
                            </p>
                        </div>

                    </div>
                }
                status={pageStatus}
                body={
                <div className='Task-Container'>

                    {/* Activity Feed */}
                    <div className='Activity-Feed'>
                        <p className='text-[16px] font-medium px-[25px] py-[15px] m-0'>Activity</p>
                        <hr className='m-0'></hr>

                        <ActivityFeed task={task}></ActivityFeed>
                    </div>

                    <TabViewByStage
                        defaultView={1}
                        currentStage={task?.stage}
                        oneTitle={'Triage'}
                        oneRoute={`/${routerUseLocation.pathname.split('/')[1]}/${routerUseLocation.pathname.split('/')[2]}?view=triage`}
                        oneContent={
                            <TriageTab
                                task={task}
                            ></TriageTab>
                        }
                        twoTitle={'PO'}
                        twoRoute={`/${routerUseLocation.pathname.split('/')[1]}/${routerUseLocation.pathname.split('/')[2]}?view=po` }
                        twoContent={
                            <POTab
                                task={task}
                                setPageStatus={setPageStatus}
                            ></POTab>
                        }
                    ></TabViewByStage>


                </div>
                }
            ></PageComponent>
        );
}
