//================================================================
//  Component: Tab View By Stage
//================================================================

//  Purpose: Simple way to build a tab view by stage for content related to tasks & supports query strings 'https://example.com?view=default'

//  Properties:
//    - defaultView = {A string, default view for the tabular view}
//    - currentStage = {A string, gives you the current stage for the task}
//    - currentStageTabOrder = {A number, gives you the order of the current stage in the tab component}
//    - oneTitle = {A string, title of the tab}
//    - oneContent = {HTML, pass in the content}
//    - oneRoute = {string, The route and query string}
//    - twoTitle = {A string, title of the tab}
//    - twoContent = {HTML, pass in the content}
//    - twoRoute = {string, The route and query string}
//    - threeTitle = {A string, title of the tab}
//    - threeContent = {HTML, pass in the content}
//    - threeRoute = {string, The route and query string}
//    - fourTitle = {A string, title of the tab}
//    - fourContent = {HTML, pass in the content}
//    - fourRoute = {string, The route and query string}
//    - fiveTitle = {A string, title of the tab}
//    - fiveContent = {HTML, pass in the content}
//    - fiveRoute = {string, The route and query string}
//    - sixTitle = {A string, title of the tab}
//    - sixContent = {HTML, pass in the content}
//    - sixRoute = {string, The route and query string}


//  Example:
//    <TabView
//      defaultView={1}
//      currentStage={task?.stage}
//      currentStageTabOrder={                              
//          task?.stage === 'Triage' ? 1 
//        : task?.stage === 'Review and Negotiate' ? 2
//        : task?.stage === 'Execute' ? 3 
//        : task?.stage === 'Completed' ? 4
//        : task?.stage === 'Disengagement' ? 5 : 1
//      }
//      oneTitle={"Overview"}
//      oneContent={<div>Overview content</div>}
//      oneRoute = {'/overview?view=detailed'}
//      twoTitle={"Pricing"}
//      twoContent={<div>Pricing content</div>}
//      twoRoute = {'/pricing?view=default'}
//      threeTitle={"Support"}
//      threeContent={<div>Support content</div>}
//      threeRoute = {'/support?view=default'}
//    ></TabView>    

//================================================================

//Libraries
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Components
import Tooltip from '../../../Components/Tooltip/Tooltip';

// Images
import Complete from '../../../Components/Images/Icon_CompleteStage_Green.svg';
import Edit from '../../../Components/Images/Icon_EditStage_Teal.svg';
import Disabled from '../../../Components/Images/Icon_DisabledStage_Grey.svg'

// CSS
import '../Task.css';

export default function TabView({
  defaultView,
  currentStage,
  oneTitle,
  oneContent,
  oneRoute,
  twoTitle,
  twoContent,
  twoRoute,
  threeTitle,
  threeContent,
  threeRoute,
  fourTitle,
  fourContent,
  fourRoute,
  fiveTitle,
  fiveContent,
  fiveRoute,
  sixTitle,
  sixContent,
  sixRoute,
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //Visibility of content > depends on click event for each tab
  const [activeTab, setActiveTab] = useState(defaultView);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Checks the state of the icon depending on the task stage
  function checkState(tabOrder) {

    if (currentStage === 'Publishing' || tabOrder !== 4) {
      return Edit

    } else if (currentStage === 'Completed') {
      return Complete

    } else {
      return Disabled

    }

  };

  // When a tab is clicked --> Navigate to the correct tab & update state
  function handleTabClick(id, route, title) {

    if (title === 'Publish to Inventory' && currentStage !== 'Publishing' && currentStage !== 'Completed') return;

    setActiveTab(id);
    navigate(route);

  };

  // Disable the tab when no content is passed down
  function hideTab(title) {

    // Hide the tabs if the users url doesn't match
    if (activeTab === 'not-found') return true;

    // No content passed in --> hide section
    if (title === undefined) return true;

    return false;

  };

  // Toggles between visible/hidden content
  function showContent(id) {

    if (activeTab === id) return true;

    return false;

  };

  // Toggles the style when the tab is selected
  function navStyle(id) {

    //Nav Item Style
    if (activeTab === id) {

      return 'Active-Stage-Tab'; // Selected Style

    } else if (checkState(id) === Disabled) {

      return 'Disabled-Stage-Tab'; // Disabled Style

    } else {

      return 'Stage-Tab'; // Default Style

    }

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Onload
  //  1. Check for a query string & change to that tab
  useEffect(() => {

    // Skip if there isn't a query string
    if (location.search === '') return;
    if (location.search === null) return;

    // helper function to compare routes
    function checkRoute(route) {

      if (`${location.pathname}${location.search}` === route) return true;
      return false;
    }

    // Toggle on the correct route
    if (checkRoute(oneRoute)) return setActiveTab(1);
    if (checkRoute(twoRoute)) return setActiveTab(2);
    if (checkRoute(threeRoute)) return setActiveTab(3);
    if (checkRoute(fourRoute)) return setActiveTab(4);
    if (checkRoute(fiveRoute)) return setActiveTab(5);
    if (checkRoute(sixRoute)) return setActiveTab(6);

    // Unable to find the correct tab --> navigate the user to 'not found' page
    setActiveTab('not-found');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //  2. Check if the user is allowed to access this stage (via URL)
  useEffect(() => {

    const tab = searchParams.get(('view'))

    if (tab === 'publishtoinventory' && currentStage !== 'Publishing' && currentStage !== 'Completed') {

      //Go back to Triage tab
      navigate(`${location.pathname}?view=triage`);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <>

      {/* Tabs */}
      <div hidden={hideTab(oneTitle)} className='Task-Stages'>

        <div hidden={hideTab(oneTitle)} className={navStyle(1)} onClick={() => handleTabClick(1, oneRoute, oneTitle)}>
          <label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{oneTitle}</label>
        </div>

        <div hidden={hideTab(twoTitle)} className={navStyle(2)} onClick={() => handleTabClick(2, twoRoute, twoTitle)}>
          <label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{twoTitle}</label>
        </div>

        <div hidden={hideTab(threeTitle)} className={navStyle(3)} onClick={() => handleTabClick(3, threeRoute, threeTitle)}>
          <label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{threeTitle}</label>
        </div>

        <div hidden={hideTab(fourTitle)} className={navStyle(4)} onClick={() => handleTabClick(4, fourRoute, fourTitle)}>
          <img src={checkState(4)} alt={fourTitle}></img>
          <Tooltip
            message={currentStage === 'Publishing' || currentStage === 'Completed' ? '' : 'Proceed to this stage by clicking on the button at the bottom of the Diligence & Governance page.'}
            children={<label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{fourTitle}</label>
            }
          ></Tooltip>
        </div>

        <div hidden={hideTab(fiveTitle)} className={navStyle(5)} onClick={() => handleTabClick(5, fiveRoute, fiveTitle)}>
          <label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{fiveTitle}</label>
        </div>

        <div hidden={hideTab(sixTitle)} className={navStyle(6)} onClick={() => handleTabClick(6, sixRoute, sixTitle)}>
          <label className='text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer'>{sixTitle}</label>
        </div>

      </div>

      {/* Content */}
      <>
        {
          showContent(1) === true &&
          oneContent
        }
      </>

      <>
        {
          showContent(2) === true &&
          twoContent
        }
      </>

      <>
        {
          showContent(3) === true &&
          threeContent
        }
      </>

      <>
        {
          showContent(4) === true &&
          fourContent
        }
      </>

      <>
        {
          showContent(5) === true &&
          fiveContent
        }
      </>

      <>
        {
          showContent(6) === true &&
          sixContent
        }
      </>

      {/* Not-Found Page */}
      <div hidden={!showContent('not-found')} className='mx-[0%] my-[2%]'>
        <div className='p-[10px] text-center'>
          <h3>Error 404 - Page not found.</h3>
          <br></br>
          <div>
            If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the above url.
            <div>
              <button className='Primary-Button' onClick={() => navigate('/')}>Return Home</button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
