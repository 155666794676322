//================================================================
//  Component: PageComponent
//================================================================

//  Purpose:
//    1. Provides a standardised page container that handles styling
//    2. Prevents unauthorized access via a role check
//    3. Advises customers to choose a 'selectedResource'
//    4. Provides standardised 'status' for success pages, errors, etc

//  Properties:
//
//  <REQUIRED>
//    - header = Provide HTML content
//    - body = Provide HTML content
//
//  <OPTIONAL>
//    - requiredRoles = Provide an array of roles. Refer to the predefined 'roles' in the users collection
//    - requiredRolesValue = Provide a string or boolen, the user MUST have this value in ONE of the 'requiredRoles'
//    - status = Provide a useState of the page status, 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
//    - breadcrumb = Provide an Object with the 'name' and 'route', {'name': 'Home', 'route': '/home'}
//    - successContent = Provide a HTML content for the successContent page
//    - errorOtherContent = Provide a HTML content for the error-other page

//  Example:
//
//    <PageComponent
//      header={ HTML Content }
//      body={ HTML Content }
//      requiredRoles={ ['isAdmin'] }
//      requiredRolesValue={ true }
//      status={ 'success' }
//      breadcrumb={ {'name': 'Home', 'route': '/home'} }
//      successContent={ HTML Content }
//      errorOtherContent={ HTML Content }
//    ></PageComponent>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';

//Contexts
import {GetUser, GetAppErrors, SetAppErrors} from '../../Library/GlobalContexts';

//Functions
import writeDocument from '../../Library/WriteDocument';

//Components
import Breadcrumbs from '../Breadcrumbs/BreadCrumbs';

//Functions

//Images
// import UnauthorisedUser from '../Images/Image_unauthorised_User.svg';
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';

//CSS is handled in index.css

export default function PageComponent({
  header,
  body,
  requiredRoles,
  requiredRolesValue,
  status,
  breadcrumb,
  successContent,
  errorOtherContent,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getAppErrors = useContext(GetAppErrors);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Handles what the user will see > 'pending', 'selectview', 'selectresource', 'accessdenied', 'invalidenvironment', 'complete' (catch all!)
    const [pageStatus, setPageStatus] = useState('pending');

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> validate if the page can be loaded
    useEffect(()=>{

      if (getUser?.roles === undefined) return;

      // Extract the current view, resource and roles
      const userRoles = getUser.roles;

      //------------------------------------------------------
      // Validate users roles
      //------------------------------------------------------

      if (requiredRoles !== undefined && requiredRoles?.length > 0) {

        let hasRole = false;

        // Loop each of the provided roles
        requiredRoles?.forEach(role => {

          // Check if 'requiredRolesValue'exists in the provided roles
          if (userRoles[role] === requiredRolesValue) {

            hasRole = true;

          } 

        });

        if (hasRole === false) {

          return setPageStatus('accessdenied');

        }

      }

      //------------------------------------------------------
      // All validations are completed
      //------------------------------------------------------

      return setPageStatus('complete');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // Error hander --> Write any clientside errors to firestore
    //Checks for getAppErrors mesages and writes them to the 'failures' collection in Firestore
    useEffect(() => {

      if (getAppErrors === undefined) return;
      if (getUser === undefined) return;
      if (status !== 'error-fatal') return;

      const documentId = `${Date.now()}`;
      const document ={
        id: `${documentId}`,
        emailaddress: `${getUser.emailaddress}`,
        message: `${getAppErrors}`
      };

      //Send and forget > the unknown error could be prevent the user from accessing Firestore!
      writeDocument('failures', documentId, document, false);

      //Clear any old message
      setAppErrors(undefined);

    // eslint-disable-next-line 
    }, [getAppErrors]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //============================
    // Pending
    //============================

    if (pageStatus === 'pending') {
      return null;
    }

    //============================
    // Access denied > user doesn't have the correct role
    //============================

    else if (pageStatus === 'accessdenied'){
      return (
        <div className='PageComponent-Container'>
          <div className='bg-[#FAFAFA] mb-[2%] p-[4%] rounded-[5px] text-center'>
            <h3 className='mb-[15px]'>Access Denied</h3>
            {/* <img className='w-[350px] text-center' style={{display: 'flex', textAlign: 'center'}} src={UnauthorisedUser} alt='Access Denied'></img> */}
            <p className='leading-[1.7]'>
              Sorry it seems like you do not have the necessary permissions to access this page.
              <br></br>
              If you feel this is a mistake, log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <button className='Primary-Button' onClick={() => window.location.reload()}> Try Again </button>
          </div>
        </div>

      )
    }

    //============================
    // All validation checks were passed, load the page!
    //============================

    else {

      return (
        <div className='PageComponent-Container'>

          {/* ------------------------------------------- */}
          {/*  Header                                     */}
          {/* ------------------------------------------- */}

          {
            header &&

            <div>
            
              {/* Breadcrumbs */}
              {
                breadcrumb &&
                  <Breadcrumbs
                    pageName={breadcrumb?.name}
                    pageRoute={breadcrumb?.route}
                    pageView={breadcrumb?.view}
                    pageResource={breadcrumb?.resource}
                  ></Breadcrumbs>
              }

              {/* Header */}
              {header}

            </div>

          }

          {/* ------------------------------------------- */}
          {/*  Body                                       */}
          {/* ------------------------------------------- */}

          <div className='PageComponent-Body'>

            {

              //============================
              //  Pending Page
              //============================

              status === 'pending' ? 
              (
                <div className='Page-Section-Container p-[2% 4%]'>
                  <div className='flex justify-center items-center mb-[2%] p-[4%] rounded-[5px] text-center'>
                    <img alt='loading-circle-icon' src={LoadingIcon}></img>
                  </div>
                </div>
              )
              
              //============================
              //  Success page
              //============================

              : status === 'success' ? 
              (
                <div className='p-[2% 4%]'>
                  {successContent}
                
                </div>

              )

              //============================
              // Error Other page
              //============================

              : status === 'error-other' ? 
              (

                <div className='p-[2% 4%]'>
                  {errorOtherContent}
                </div>
                
              )

              //============================
              // Error-Invalid page
              //============================

              : status === 'error-invalid' ? 
              (

                <div className='bg-[#FAFAFA] mb-[2%] p-[4%] rounded-[5px] text-center'>
                  <h2 className='mb-[15px]'>Oops something went wrong</h2>
                  <p className='leading-[1.7]'>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the error message below. 
                  </p>
                  <p className='leading-[1.7]'> 
                    <b>Message:</b> {getAppErrors}
                  </p>
                  <button className='Primary-Button' onClick={() => window.location.reload()}> Try Again </button>
                </div>

              )

              //============================
              // Error-Fatal or Error-Timeout page
              //============================

              : status === 'error-fatal' || status === 'error-timeout' ? 
              (

                <div className='bg-[#FAFAFA] mb-[2%] p-[4%] rounded-[5px] text-center'>
                  <h2 className='mb-[15px]'>Oops something went wrong</h2>
                  <p className='leading-[1.7]'>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the error message below. 
                  </p>
                  <p className='leading-[1.7]'> 
                    <b>Message:</b> Failed to load the page - Fatal Error.
                  </p>
                  <button className='Primary-Button' onClick={() => window.location.reload()}> Try Again </button>
                </div>

              )

              //============================
              // Catch all > show the default body
              //============================

              : status === 'onload' ? 
              (

                <>
                  {body}
                </>

              ) : (

                <>
                  {body}
                </>

              )
            }
          </div>

        </div>
      )
    }
  
}
