//================================================================
//  Page: My Tasks
//================================================================

//  Purpose: This page shows all Procurement requests assigned to the current user

//================================================================


// Libraries
import React, { useReducer } from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import InProgress from './Tabs/InProgress';
import Completed from './Tabs/Completed';
import All from './Tabs/All';

// Functions

// Images

// CSS
import './MyTasks.css';


export default function MyTasks() {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store the total requests per stage/view
  const [requests, setRequests] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'inProgressRequests': [],
      'inProgressRequestsFiltered': [],
      'completedRequests': [],
      'completedRequestsFiltered': [],
      'allRequests': [],
      'allRequestsFiltered': [],
    }
  );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      header={
        <div className='Page-Header-Container'>
          <p className='font-medium text-[20px] mb-0'> My Tasks </p>
        </div>
      }
      body={
        <TabView
          defaultView={1}

          //------------------------------------------------------
          //  In Progress
          //------------------------------------------------------

          oneTitle={
            <div className='flex flex-row gap-2'>
              In Progress
            </div>
          }
          oneRoute={'/mytasks?view=inprogress'}
          oneContent={
            <InProgress
              requests={requests}
              setRequests={setRequests}
            ></InProgress>
          }
          //------------------------------------------------------
          //  Completed
          //------------------------------------------------------

          twoTitle={'Completed'}
          twoRoute={'/mytasks?view=completed'}
          twoContent={
            <Completed
              requests={requests}
              setRequests={setRequests}
            ></Completed>
          }

          //------------------------------------------------------
          //  All
          //------------------------------------------------------

          fiveTitle={'All'}
          fiveRoute={'/mytasks?view=all'}
          fiveContent={
            <All
              requests={requests}
              setRequests={setRequests}
            ></All>
          }

        ></TabView>
      }
    ></PageComponent>
  )
}
